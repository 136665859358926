<template>
  <v-card>
    <v-card-text>
      <v-switch
        aut-sidebar-toggle
        dense
        v-model="display"
        :disabled="isDisabled"
        label="Display Sidebar"
      ></v-switch>
      <FeatureSwitch
        v-if="display"
        :features="sidebarFeatures"
        @update="updateFeature"
      />
    </v-card-text>
  </v-card>
</template>
<script>
import { set } from "lodash";
import { mapActions, mapGetters } from "vuex";
import FeatureSwitch from "@/views/administration/FeatureFlags/FeatureSwitches/FeatureSwitches.vue";
import { sidebarFeatures } from "@/settings";

export default {
  name: "SettingsSidebar",
  props: {
    definition: {
      type: Object,
    },
  },
  components: {
    FeatureSwitch,
  },
  data() {
    return { sidebarFeatures };
  },
  computed: {
    ...mapGetters(["isClassicLayout"]),
    isDisabled() {
      return !this.isClassicLayout;
    },
    display: {
      get() {
        let result = false;
        if (!this.isClassicLayout) {
          result = false;
        } else {
          result = this.definition?.sidebar?.display || false;
        }
        return result;
      },
      set(value) {
        this.saveSettingsToDomain({
          sidebar: {
            display: value,
          },
        });
      },
    },
  },
  methods: {
    ...mapActions(["saveSettingsToDomain"]),
    updateFeature({ name, value }) {
      const settings = {};
      set(settings, name, value);
      this.saveSettingsToDomain(settings);
    },
  },
};
</script>
